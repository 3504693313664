import React, { useState, useContext, useEffect } from 'react';
import './horizontalTabsAdmin.scss';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Link, Filters, FiltersSelect } from '@ui-kit';
import { SearchFormBg } from '@features';
import {
  ADMIN_PROJECTS_URL,
  ADMIN_USERS_URL,
  ADMIN_PRODUCTS_URL,
  //ADMIN_MANAGEMENT_SETTINGS_URL,
  ADMIN_ARCHIVE_PROJECTS_URL,
} from '@app/routes';

import { ROLES } from '@entities/user';
import {
  getUserRole,
  getArchiveProjectsTC,
  getProjectsTC,
  getUsersTC,
  getSettingsTC,
  getReadyProductsTC,
  getPromotedProductsTC,
  getSearchPage,
  setProjectsSearchValueAC,
} from '@entities';
import RefreshIcon from '@ui-kit/icons/refresh-cw-gray500.svg';
import { ProjectsContext, UsersContext, ProductsContext } from '@shared/hooks';

const HorizontalTabsAdmin = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const projectsContext = useContext(ProjectsContext);
  const usersContext = useContext(UsersContext);
  const productsContext = useContext(ProductsContext);
  const role = useSelector(getUserRole);
  const searchPage = useSelector(getSearchPage);
  const [searchValue, setSearchValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const isAdmin = role === ROLES.ADMIN;
  let filtersList = [];
  let filterOrder = '';
  let actions = '';
  const projectsList = [
    { title: 'Select a state', 
      order: 'state',
      list: [
        { name: 'Brief', value: '1' },
        { name: 'Sketching', value: '3' },
        { name: 'Illustrating', value: '5' },
        { name: 'Completed', value: '8' },
      ]
    },
    { title: 'Select a status', 
      order: 'status',
      list: [
        { name: 'Sketch is ready', value: 'sketchReady'},
        { name: 'Illustration is ready', value: 'illustrationReady' },
        { name: 'Waiting for a response', value: 'responseReady' },
        { name: 'Switching to exclusive license', value: 'licenseReady' },
        { name: 'Work in progress', value: 'workReady' },
        { name: 'Source files are ready', value: 'filesReady' },
      ]
    },
  ];

  useEffect(() => {
    const search = searchParams.get('search');
    if (search && !searchValue) {
      setSearchValue(search);
    };
    // eslint-disable-next-line
  }, []);

  const resetFilter = (filterOrder) => {
    searchParams.delete(filterOrder);
    setSearchParams(searchParams);

    onUpdateData();
  }

  const searchPlaceholder = () => {
    switch (searchPage) {
      case 'projects': {
        filtersList = projectsList;
        return 'Search by projects';
      }

      case 'archive': {
        filtersList = projectsList;
        return 'Search by archive projects';
      }

      case 'users': {
        filterOrder = 'search';
        filtersList = [
          { name: 'Users', value: 'user' },
          { name: 'Executors', value: 'executor' },
          { name: 'Admins', value: 'admin' },
        ];
        actions = (
          <div className="filter-actions__line">
            <Button 
              size="sm" 
              theme="link-gray"
              onClick={() => resetFilter(filterOrder)}
            >Reset</Button>
          </div>
        );
        return 'Search by users';
      }

      case 'products': {
        filterOrder = 'isPublic';
        filtersList = [
          { name: 'Active', value: '1' },
          { name: 'Hidden', value: '0' },
        ];
        return 'Search by products';
      }

      case 'promote': {
        filterOrder = 'fieldType';
        filtersList = [
          { name: 'Newest', value: 'createdAt' },
          { name: 'Popular', value: 'popular' },
        ];
        return 'Search by promo products';
      }

      default:
        return 'Search by';
    };
  };

  const onSubmitSearch = (e) => {
    e.preventDefault();
  };

  const onChangeSearch = async(event) => {
    const value = event?.target?.value || '';

    setSearchValue(value);

    switch (searchPage) {
      case 'archive': {
        dispatch(setProjectsSearchValueAC(value));
        await projectsContext.onProjectsSearch(value);
        break;
      }

      case 'projects': {
        dispatch(setProjectsSearchValueAC(value));
        await projectsContext.onProjectsSearch(value);
        break;
      }

      case 'users': {
        await usersContext.onUsersSearch(value);
        break;
      }

      case 'products': {
        await productsContext.onProductsSearch(value);
        break;
      }

      case 'promote': {
        await productsContext.onProductsSearch(value);
        break;
      }
      
      default : break;
    };
        
    event.preventDefault();
  };

  const isActiveButton = (url) => {
    const path = url.pathname;

    if (path === location.pathname) {
      return 'active';
    }

    return '';
  };

  const onUpdateData = () => {
    switch (searchPage) {
      case 'archive': {
        dispatch(getArchiveProjectsTC({ limit: 30, page: 1 }));
        break;
      }

      case 'projects': {
        dispatch(getProjectsTC({ limit: 30, page: 1 }));
        break;
      }

      case 'users': {
        dispatch(getUsersTC({ limit: 30, page: 1 }));
        break;
      }

      case 'products': {
        dispatch(getReadyProductsTC({ limit: 80, page: 1, isPublic: 1 }));
        break;
      }

      case 'promote': {
        dispatch(getReadyProductsTC({ limit: 30, page: 1 }));
        break;
      }

      case 'settings': {
        dispatch(getSettingsTC());
        break;
      }
      
      default : break;
    };
  };

  const onChangeFilter = () => {
    const search = window.location.search;
    switch (searchPage) {
      case 'projects': {
        dispatch(getProjectsTC(search));
        break;
      }

      case 'archive': {
        dispatch(getArchiveProjectsTC(search));
        break;
      }

      case 'users': {
        dispatch(getUsersTC(search));
        break;
      }

      case 'products': {
        dispatch(getReadyProductsTC(search));
        break;
      }

      case 'promote': {
        dispatch(getPromotedProductsTC(search));
        break;
      }

      case 'settings': {
        dispatch(getSettingsTC());
        break;
      }
      
      default : break;
    }
  };

  const renderFilters = () => {
    if (searchPage === 'archive' || searchPage === 'projects') {
      return (
        <FiltersSelect 
          filters={filtersList}
          onChangeFilter={onChangeFilter}
        />
      )
    }

    return (
      <Filters 
      filters={filtersList} 
      filterOrder={filterOrder} 
      actions={actions}
      onChangeFilter={onChangeFilter}
    />
    );
  };

  return (
    <div className="admin-navigate d-flex df-column jc-sb">
      <div className="admin-navigate__menu d-flex df-column">
        <Button
          theme="link-gray"
          size="lg"
          iconRight={RefreshIcon}
          className="mr-32 refresh-page"
          onClick={onUpdateData}
        />
        <div className="admin-navigate__tab d-flex df-column">
          <Link
            to={ADMIN_PROJECTS_URL}
            className={`tab-link ${isActiveButton(ADMIN_PROJECTS_URL)}`}
            theme="text"            
          >
            Projects
          </Link>
          {isAdmin && (
            <Link
              to={ADMIN_USERS_URL}
              theme="text"
              className={`tab-link ${isActiveButton(ADMIN_USERS_URL)}`}
            >
              Users
            </Link>
          )}
          {isAdmin && (
            <Link
              to={ADMIN_PRODUCTS_URL}
              className={`tab-link ${isActiveButton(ADMIN_PRODUCTS_URL)}`}
              theme="text"
            >
              Products
            </Link>
          )}
          <Link
              to={ADMIN_ARCHIVE_PROJECTS_URL}
              className={`tab-link ${isActiveButton(ADMIN_ARCHIVE_PROJECTS_URL)}`}
              theme="text"
              >
              Archive
            </Link>
        </div>
      </div>
      <div className="admin-navigate__search d-flex df-column">
        <SearchFormBg
          onSubmit={onSubmitSearch}
          onChange={onChangeSearch}
          placeholder={searchPlaceholder()}
          className="mr-32"
          value={searchValue}
        />
        {renderFilters()}
      </div>
    </div>
  );
};

export default HorizontalTabsAdmin;
