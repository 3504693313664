import React, { useCallback, useContext } from 'react';
import './table-projects.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  getAdminProjectTC,
  setCurrentProjectImageAC,
  setSelectedProjectAC,
  getProjectWasChanged,
  setIsUnsavedProjectShowAC,
  getCustomer,
  getIsMobileScreenView,
  setisProjectChangeAC,
} from '@entities';
import ProjectItem from './ProjectItem';
import { ProjectsContext } from '@shared/hooks';

const TableProjects = ({setIsChatView}) => {
  const dispatch = useDispatch();
  const projectIsNotSaved = useSelector(getProjectWasChanged);
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const currentUser = useSelector(getCustomer);
  const isMobileScreenView = useSelector(getIsMobileScreenView);
  const updatedProjects = currentUser?.updatedProjects;
  const projectsContext = useContext(ProjectsContext);
  const { projects, currentProjectId, isArchive } = projectsContext;

  const projectsParams = () => {
    return window.location.search
    .slice(1)
    .split('&')
    .map(p => p.split('='))
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
  };

  const onChoose = useCallback((project) => {
    if (isMobileScreenView && (currentProjectId === project.id)) {
      const params = {...projectsParams(), project_asset_id: project.id}
      setSearchParams(params);

      return setIsChatView(true);
    }

    if(projectIsNotSaved && !isArchive) {
      return dispatch(setIsUnsavedProjectShowAC(true));
    }

    if (isArchive) {
      dispatch(setisProjectChangeAC(false));
    }

    window.roomId = null;
    dispatch(getAdminProjectTC(project.id));
    dispatch(setCurrentProjectImageAC(null));
    dispatch(setSelectedProjectAC(project.id));
    
    const params = {...projectsParams(), project_asset_id: project.id}
    setSearchParams(params);
    setIsChatView(true);
    // eslint-disable-next-line
  }, [projectIsNotSaved]);

  return (
    <div className="projects__panel">
      <div className="admin__table">
        <div className="admin__table-body" id="table">
          {projects.map((item, index) => (
            <ProjectItem
              key={`admin-project-${item.id}`}
              project={item}
              onClick={onChoose}
              updatedProjects={updatedProjects}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TableProjects;
